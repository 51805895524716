import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Circle3pxHalfVert from '@static/img/tracklines/circles/circle-3px-halfline-vertical.inline.svg'
import Circle3pxT from '@static/img/tracklines/circles/circle-3px-T.inline.svg'

import Select from '@objects/select'

const StyledFilter = styled.div.attrs({
  className: 'mb-8 hidden md:flex items-end',
})`
  .circle {
    transform: translateY(43%);
    font-size: 19px;

    circle,
    line {
      stroke: ${({ theme }) => theme.colors.blue.default};
    }

    &.start {
      margin-right: -11px;
      z-index: 2;
    }
    &.end {
      margin-left: auto;
    }
  }
`

const StyledFilterItems = styled.div.attrs({
  className: 'flex items-end flex-1 relative',
})`
  overflow-x: auto;
  overflow-y: hidden;

  &:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-bottom: 3px solid ${({ theme }) => theme.colors.blue.default};
  }
`

const StyledFilterItem = styled.button.attrs({
  className: 'px-5 py-1 relative cursor-pointer',
})`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  &.active {
    z-index: 1;
    border: 3px solid ${({ theme }) => theme.colors.blue.default};
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    color: ${({ theme }) => theme.colors.red.default};
    font-weight: 500;

    &:before,
    &:after {
      z-index: 0;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background: #fff;
      position: absolute;
      bottom: 0;
    }

    &:before {
      left: -8px;
    }
    &:after {
      right: -8px;
    }
  }

  &.first {
    &:before {
      content: none;
    }
  }
`

const StyledFilterItemInner = styled.div`
  background-color: #fff;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.colors.red.default};
  }

  .active & {
    &:before,
    &:after {
      z-index: 1;
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background: #fff;
      position: absolute;
      bottom: 0;
      border-radius: 50%;
      border: 3px solid ${({ theme }) => theme.colors.blue.default};
    }

    &:before {
      left: -16px;
      clip: rect(8px, 16px, 16px, 8px);
    }
    &:after {
      right: -16px;
      clip: rect(8px, 8px, 16px, 0);
    }
  }

  .first & {
    &:before {
      content: none;
    }
  }
`

function Filter({
  className,
  filteritems,
  currentFilter,
  setCurrentFilter,
  filterAll,
}) {
  const itemAllMobile = {
    value: filterAll.id,
    label: filterAll.label.mobile,
  }

  function filterButtonClick(name) {
    setCurrentFilter(name)
  }

  function renderButton(id, name) {
    return (
      <StyledFilterItem
        key={id}
        className={clsx({
          active: currentFilter === id,
          first: id === filterAll.id,
        })}
        onClick={() => filterButtonClick(id)}
        role="option"
        aria-selected={currentFilter === id}
      >
        <StyledFilterItemInner>{name}</StyledFilterItemInner>
      </StyledFilterItem>
    )
  }

  function renderFilterButtons() {
    return [renderButton(filterAll.id, filterAll.label.desktop)].concat(
      filteritems.map((item) => renderButton(item.filterId, item.title))
    )
  }

  function changeEvent(selected) {
    filterButtonClick(selected.value)
  }

  return (
    <>
      <StyledFilter>
        <Circle3pxT className={'circle start'} />
        <StyledFilterItems
          role="listbox"
          aria-orientation="horizontal"
          aria-controls="bloglist"
        >
          {renderFilterButtons()}
        </StyledFilterItems>
        <Circle3pxHalfVert className={'circle end'} />
      </StyledFilter>
      <div className="mb-8 md:hidden">
        <p id="filterlabel" className="text-lg text-center mb-3 font-medium">
          <FormattedMessage id="label.filter" />
        </p>
        <Select
          options={[itemAllMobile].concat(
            filteritems.map((item) => {
              return {
                value: item.filterId,
                label: item.title,
              }
            })
          )}
          defaultValue={itemAllMobile}
          onChange={changeEvent}
          label="filterlabel"
        />
      </div>
    </>
  )
}

Filter.propTypes = {
  className: PropTypes.string,
  filteritems: PropTypes.array.isRequired,
  currentFilter: PropTypes.string.isRequired,
  setCurrentFilter: PropTypes.func.isRequired,
  filterAll: PropTypes.object,
}

export default Filter
