import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { useIntl } from 'react-intl'

import queryString from 'query-string'

import Container from '@objects/container'
import TeaserCard from '@objects/teaserCard'
import Button from '@objects/button'
import Stage from '@components/stage'
import Filter from './filter'

const StyledSpacer = styled.hr.attrs({
  className: 'md:h-9 md:w-full border-none',
})``

function BlogList({
  className,
  hero,
  items,
  categoryfilter,
  filterAllLabel,
  showmore,
  initialshow,
  loadpershowmore,
  cardType,
  columns,
}) {
  const intl = useIntl()
  const location = useLocation()
  const CompRef = useRef(null)

  const fullteaserlist = items.map((card) => {
    card.filterId = card.tag?.toLowerCase().replace(/ /g, '') || ''
    return card
  })
  const categoryfilterWithId = categoryfilter?.map((cat) => {
    cat.filterId = cat.title?.toLowerCase().replace(/ /g, '') || ''
    return cat
  })
  const filterAllId = filterAllLabel.desktop.toLowerCase().replace(/ /g, '')

  const [loadnumber, setLoadnumber] = useState(initialshow || 12)
  const [hideloadmore, setHideloadmore] = useState(false)

  const [currentFilter, setCurrentFilter] = useState(filterAllId)
  const [filteredTeaserList, setFilteredTeaserList] = useState(fullteaserlist)

  useEffect(() => {
    setHideloadmore(false)
    setFilteredTeaserList(
      items.filter(
        (t) =>
          !categoryfilter ||
          currentFilter === filterAllId ||
          currentFilter === t.filterId
      )
    )

    if (!!categoryfilter?.length) {
      navigate(
        `${location.pathname}#${queryString.stringify({
          articlefilter: currentFilter,
        })}`,
        {
          replace: true,
          state: {
            preventScroll: true,
          },
        }
      )
    }
  }, [currentFilter])

  useEffect(() => {
    const filter = queryString.parse(location.hash).articlefilter
    if (location.hash?.length) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 200)
    }

    const valid = categoryfilterWithId?.find((cat) => cat.filterId === filter)

    if (filter && valid && filter !== currentFilter) {
      setCurrentFilter(filter)
    }
  }, [location.hash])

  function renderTeaser() {
    let result = []
    let teaserCount = 0

    for (let i = 0; i < filteredTeaserList.length; i++) {
      const item = filteredTeaserList[i]

      if (i === 6) {
        result.push(<StyledSpacer key="spacer" />)
      }

      result.push(
        <TeaserCard
          key={i}
          className={clsx(
            'md:px-3',
            columns
              ? columns
              : {
                  'mb-9 lg:w-1/2': i < 6,
                  'mb-3 md:mb-7 md:w-1/2 lg:w-1/3': i > 5,
                }
          )}
          type={cardType ? cardType : i < 6 ? 'blog' : 'blogShort'}
          image={item.image}
          headline={item.headline}
          copy={item.copy}
          date={item.date}
          link={item.link}
          tag={item.tag}
          reverse
          role="listitem"
        />
      )

      teaserCount++

      // break if showmore
      if (showmore && teaserCount === loadnumber) {
        break
      }
    }
    if (
      showmore &&
      !hideloadmore &&
      teaserCount === filteredTeaserList.length
    ) {
      setHideloadmore(true)
    }
    return result
  }

  function onLoadMoreClick() {
    setLoadnumber(loadnumber + loadpershowmore)
  }

  return (
    <>
      {categoryfilter && (
        <Container nopadding className="">
          <Filter
            filteritems={categoryfilterWithId}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            filterAll={{
              label: filterAllLabel,
              id: filterAllId,
            }}
          />
        </Container>
      )}

      {hero && hero.image && currentFilter === filterAllId && (
        <Stage
          className={'mb-13'}
          type="infocard"
          image={{ ...hero.image, desktopAspectRatio: 2.38 }}
          infocard={{
            headline: hero.headline,
            copy: hero.copy,
            button: {
              label: intl.formatMessage({ id: 'button.getmore' }),
              link: hero.link,
            },
            date: hero.date,
            tag: {
              name: hero.tag,
              to: '/blog/',
              filter: hero.tag,
              size: 'lg',
            },
            truncate: 25,
          }}
          options={{ hideScrollIcon: true, bottomline: true }}
        />
      )}
      <Container
        className={'mb-13'}
        nopadding
        role="region"
        aria-label="BlogList"
      >
        <div
          ref={CompRef}
          data-testid="list"
          className={clsx(className, 'md:flex md:flex-wrap md:-mx-3')}
          role="list"
          id="bloglist"
        >
          {renderTeaser()}
        </div>
        {showmore && !hideloadmore && (
          <div className="text-center mt-8 md:mt-4">
            <Button showmore onClick={onLoadMoreClick}>
              {intl.formatMessage({ id: 'button.loadmore' })}
            </Button>
          </div>
        )}
      </Container>
    </>
  )
}

BlogList.propTypes = {
  className: PropTypes.string,
  hero: PropTypes.shape({
    link: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    headline: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
    date: PropTypes.string,
    tag: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      image: PropTypes.object,
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
      date: PropTypes.string,
      tag: PropTypes.string,
    })
  ).isRequired,
  categoryfilter: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  filterAllLabel: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }),
  showmore: PropTypes.bool,
  initialshow: PropTypes.number,
  loadpershowmore: PropTypes.number,
  cardType: PropTypes.string,
  columns: PropTypes.string,
}

BlogList.defaultProps = {
  filterAllLabel: {
    mobile: 'Alle Kategorien',
    desktop: 'Alle',
  },
}

export default styled(BlogList).attrs({
  className: 'relative',
})``
