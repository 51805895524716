import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import useDateFns from '@hooks/useDateFns'

import BlogList from '@components/blogList'
import Headline from '@objects/headline'
import Container from '@objects/container'

function EasyLanguage({ data }) {
  const intl = useIntl()
  const { differenceInDays } = useDateFns()

  const easyLanguageNews = get(data, 'contentfulArticleList')
    ?.articles.sort((a, b) => differenceInDays(b.createdAt, a.createdAt))
    .map((item) => ({
      image: item.image,
      headline: item.title,
      copy: item.teaserCopy?.childMarkdownRemark?.html,
      tag: item.category?.title,
      date: item.createdAt,
      link: item.slug,
    }))

  return (
    <>
      <Container nopadding role="region" aria-label="Leichte Sprache">
        <Headline
          level={1}
          className="my-8 py-1 text-3xl md:text-5xl"
          ariaLabel={intl.formatMessage({ id: 'news.easyLanguage.headline' })}
        >
          {intl.formatMessage({ id: 'news.easyLanguage.headline' })}
        </Headline>
      </Container>

      <BlogList
        cardType={'easyLanguage'}
        columns={'mb-9 lg:w-full md:h-250 lg:h-250'}
        items={easyLanguageNews}
      />
    </>
  )
}

EasyLanguage.propTypes = {
  data: PropTypes.any,
}

export default EasyLanguage

export const pageQuery = graphql`
  query EasyLanguageNewsPageQuery($locale: String!) {
    contentfulArticleList(
      node_locale: { eq: $locale }
      identifier: { eq: "leichte-sprache" }
    ) {
      articles {
        slug
        title
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        createdAt
        image {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        category {
          title
        }
      }
    }
  }
`
